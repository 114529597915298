const apiLocation = 'https://api.orthoptiste-tournefeuille.com/v1'; //PROD API LOCATION
//const apiLocation = 'http://localhost:3000/v1';
const okMessage = "<p>Le message a été correctement transmis à notre équipe, nous reviendrons vers vous le plus rapidement possible!<p><p class='text-center'><a class='btn btn-primary text-light' href='/'>Retour à l'accueil</a></p>"
import router from '../router/index'

const parseContactObject = (captchaResponse, form) => {
    return {
        captcha: captchaResponse.value,
        firstName: form.querySelector('#firstName').value,
        lastName: form.querySelector('#lastName').value,
        email: form.querySelector('#email').value,
        tel: form.querySelector('#tel').value,
        field: parseInt(form.querySelector('#field').value, 10),
        subject: form.querySelector('#subject').value,
        plainText: form.querySelector('#plainText').value
    };
}

const showMailSucces = () => {
    const modalTitle = document.querySelector('#infoModalTitle');
    const modalParagraph = document.querySelector('#infoModalText');
    document.querySelector('#closeButton').style.display = 'none';
    modalTitle.innerText = "Message envoyé!";
    modalParagraph.innerHTML = okMessage;
    window.$('#infoModal').modal({
        backdrop: 'static',
        show: true
    });
    setTimeout(() => {
        window.$('#infoModal').modal('hide');
        router.push('/');
    }, 3000);
};

const showCaptchaError = (errorMessage) => {
    const spinner = document.querySelector('.contact__submit');
    const modalTitle = document.querySelector('#infoModalTitle');
    const modalParagraph = document.querySelector('#infoModalText');
    modalTitle.innerText = "Erreur de Captcha!";
    modalParagraph.innerText = errorMessage;
    window.$('#infoModal').modal('show');
    window.grecaptcha.reset()
    spinner.style.opacity = 0;
    setTimeout(() => {
        spinner.style.display = 'none';
    }, 350)
};

const showEmptyCaptcha = () => {
    const captchaDOM = document.querySelector('.contact__captcha');
    const alertCaptcha = document.querySelector('.contact__captcha__alert');
    captchaDOM.style.animationPlayState = 'running';
    alertCaptcha.style.display = 'block';
    setTimeout(() => {
        alertCaptcha.style.opacity = 1;
        setTimeout(() => {
            alertCaptcha.style.opacity = 0;
            setTimeout(() => {
                alertCaptcha.style.display = 'none';
            }, 500)
        }, 5000)
    }, 100);
}

const showGenericMailError = (mailErrorMessage) => {
    const spinner = document.querySelector('.contact__submit');
    const modalTitle = document.querySelector('#infoModalTitle');
    const modalParagraph = document.querySelector('#infoModalText');
    modalTitle.innerText = "Erreur d'envoi du mail!";
    modalParagraph.innerText = mailErrorMessage;
    window.$('#infoModal').modal('show');
    window.grecaptcha.reset()
    spinner.style.opacity = 0;
    setTimeout(() => {
        spinner.style.display = 'none';
    }, 350)
};

const showValidationMailError = (mailErrorObject) => {
    const spinner = document.querySelector('.contact__submit');
    spinner.style.opacity = 0;
    setTimeout(() => {
        spinner.style.display = 'none';
    }, 350)
    const modalTitle = document.querySelector('#infoModalTitle');
    const container = document.querySelector('#infoModalText');
    const paragraph = document.createElement('p');
    const fieldList = document.createElement('ul');
    container.innerHTML = '';

    modalTitle.innerText = "Erreur dans les champs de formulaire";
    paragraph.innerText = 'Les champs suivants comportent des erreurs :';
    container.appendChild(paragraph);

    for (let errorKey of Object.keys(mailErrorObject)) {
        const listElement = document.createElement('li');
        switch (errorKey) {
            case 'firstName':
                listElement.innerText = 'Prénom';
                break;
            case 'lastName':
                listElement.innerText = 'Nom';
                break;
            case 'email':
                listElement.innerText = 'E-mail';
                break;
            case 'tel':
                listElement.innerText = 'Numéro de téléphone';
                break;
            case 'field':
                listElement.innerText = 'Catégorie';
                break;
            case 'subject':
                listElement.innerText = 'Objet du message';
                break;
            case 'plainText':
                listElement.innerText = 'Votre message';
                break;

        }
        fieldList.appendChild(listElement);
    }
    container.appendChild(fieldList);
    window.$('#infoModal').modal('show');
    window.grecaptcha.reset()
};

const showGenericError = () => {
    const spinner = document.querySelector('.contact__submit');
    spinner.style.opacity = 0;
    setTimeout(() => {
        spinner.style.display = 'none';
    }, 350)
    const modalTitle = document.querySelector('#infoModalTitle');
    const modalParagraph = document.querySelector('#infoModalText');
    modalTitle.innerText = "Erreur Serveur"
    modalParagraph.innerText = 'Erreur serveur, veuillez réessayer plus tard';
    window.$('#infoModal').modal('show');
    window.grecaptcha.reset()
};

export const treatForm = (form) => {
    console.log(form);
    //Récupération de la reponse du captcha
    const captchaResponse = form.querySelector('.g-recaptcha-response');
    // Animation si pas de Captcha
    if (!captchaResponse.value) {
        showEmptyCaptcha();
    } else {
        const spinner = document.querySelector('.contact__submit');
        spinner.style.display = 'block';
        setTimeout(() => {
            spinner.style.opacity = 1;
        }, 10)
        const contactObject = parseContactObject(captchaResponse, form);
        fetch(`${apiLocation}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(contactObject)
            })
            .then(response => {
                if (response.status === 200) {
                    showMailSucces();
                } else {
                    response.json()
                        .then(error => {
                            if (error.mailError) {
                                if (error.errorObject) {
                                    showValidationMailError(error.errorObject);
                                } else {
                                    showGenericMailError(error.mailError);
                                }
                            } else if (error.captchaError) {
                                showCaptchaError(error.captchaError);
                            } else {
                                // ERREUR SERVEUR QUELCONQUE
                            }
                        })
                }
            })
            .catch(() => {
                showGenericError();
            })
    }
}

/*
req.mail prototype (validateMail middleware):
{
    firstName: String,
    lastName: String,
    email: String,
    tel: String,
    field: Number from 0 to 3,
    subject: String,
    plainText: String,
}
*/