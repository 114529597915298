<template>
    <div id="page-content" class="container px-1 px-sm-4 py-lg-2 bg-light contact">
        <div ref="scriptPlaceholder"></div>
        <div class="container p-lg-3">
            <div class="row">
                <div class="col d-flex contact__heading">
                    <h2 class="mr-auto ml-auto mb-3 text-center underline">Formulaire de contact</h2>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <p class="col-lg-6 font-italic">Une question ? Des précisions ? Utilisez le formulaire
                        ci-dessous,
                        nous vous répondrons dès que possible.</p>
                </div>
            </div>
            <form id="contactForm" class="m-0 m-lg-3 p-3 contact__content rounded needs-validation"
                @submit="formSubmit($event)" ref='contactForm'>
                <div class="form-row">
                    <h4>Coordonnées:</h4>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-6">
                        <label for="firstName">Prénom</label>
                        <input type="text" class="form-control" id="firstName" placeholder="Jean" minlength="1"
                            maxlength="100" required>
                    </div>
                    <div class="form-group col-lg-6">
                        <label for="lastName">Nom</label>
                        <input type="text" class="form-control" id="lastName" placeholder="Dupont" minlength="1"
                            maxlength="100" required>
                    </div>
                </div>
                <div class="form-row">
                    <div class="col-lg-6 mb-3">
                        <label for="email">Email</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="emailPrepend">@</span>
                            </div>
                            <input type="email" class="form-control" id="email" placeholder="jeandupont@gmail.com"
                                required>
                        </div>
                        <small id="emailHelp" class="form-text text-white font-italic">Votre e-mail et vos données
                            sont totalement confidentielles.</small>
                    </div>
                    <div class="form-group col-lg-3">
                        <label for="tel">Téléphone</label>
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id=telPrepend>📱</span>
                            </div>
                            <input type="tel" class="form-control text-center" id="tel" placeholder="01.23.45.67.89"
                                maxlength="17">
                        </div>
                        <small id="telHelp" class="form-text text-white font-italic">Facultatif</small>
                    </div>
                </div>
                <hr />
                <div class="form-row">
                    <h4>Votre message:</h4>
                </div>
                <div class="form-row">
                    <div class="form-group col-lg-3">
                        <label for="field">Catégorie</label>
                        <select class="form-control" id="field" required>
                            <option label='Choisissez une catégorie'></option>
                            <option value="0">Informations générales</option>
                            <option value="1">Traitement et suivi</option>
                            <option value="2">Renouvellement de lunettes</option>
                            <option value="3">Autre sujet</option>
                        </select>
                    </div>
                    <div class="form-group col-lg-9">
                        <label for="subject">Objet du message</label>
                        <input type="text" class='form-control' name="subject" id="subject" required minlength="1"
                            maxlength="100" placeholder="Besoin de renseignements sur...">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="plainText">Écrivez dans la zone ci-dessous</label>
                        <textarea class="form-control" id="plainText" rows="4" minlength="20" maxlength="1000"
                            placeholder="Bonjour..." required></textarea>
                    </div>
                </div>
                <hr />
                <div class="form-row d-flex justify-content-center justify-content-lg-start contact__captcha">
                    <div class="g-recaptcha ml-lg-3" data-sitekey="6LeDuc0ZAAAAAG2dpSBLTQw1Sapae_2QFpyQEzkE"></div>
                </div>
                <div class="form-row d-flex justify-content-center justify-content-lg-start align-items-center">
                    <button class="m-3 btn btn-lg btn-primary text-white contact__button" type="submit">
                        Envoyer le message
                    </button>
                    <img src="@/assets/img/gif/buttonSpinner.gif" alt="Spinner button"
                        class="img-fluid contact__submit">
                    <div class="alert alert-primary contact__captcha__alert align-self-end" role="alert">
                        Pensez à valider le Captcha ci-dessus!
                    </div>
                </div>
            </form>
        </div>
        <div class="modal fade" id="infoModal" tabindex="-1" role="dialog" aria-labelledby="infoModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="infoModalTitle">Info</h5>
                        <button type="button" class="close" id="closeButton" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body__content" id="infoModalText"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        treatForm
    } from '../js/contact'
    import {
        mapActions
    } from 'vuex';

    export default {
        name: 'Contact',
        mounted() {
            window.scrollTo(0, 0);            
            this.updateActive(this.$route);
            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
            this.$refs.scriptPlaceholder.appendChild(scriptEl);
            document.title = 'Camille Barès - Formulaire de contact'

        },
        methods: {
            ...mapActions(['updateActive']),
            formSubmit(event) {
                event.preventDefault();
                treatForm(this.$refs.contactForm);
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>